import React from 'react';
import TitanPage from '../Titan/TitanPage';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  ROUTES,
  WEB_SOCKET_ACTIONS,
  COMPONENT_STATUSES,
  FUSION_JOB_ACTION_TYPES,
} from '../../constants';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import TitanInfoItem from '../Titan/TitanInfoItem';
import TitanDateTime from '../Titan/TitanDateTime';
import TitanTimeAgo from '../Titan/TitanTimeAgo';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TitanTabPanel from '../Titan/TitanTabPanel';
import { colors } from '../Theme/vars';
import CommentDialog from '../Comment/CommentDialog';
import { IconButton, Stack } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import Paper from '@mui/material/Paper';
import FusionJobService from '../../services/FusionJobService';
import FinalPartsTable from '../FinalPart/FinalPartsTable';
import FusionJobActions from './FusionJobActions';
import FusionModuleLink from '../FusionModule/FusionModuleLink';
import FusionPlanLink from '../FusionPlan/FusionPlanLink';
import FusionPlanParamsTable from '../FusionPlan/FusionPlanParamsTable';
import useWebSocket from 'react-use-websocket';
import FusionModuleTelemetry from '../FusionModule/FusionModuleTelemetry';
import FusionModuleLogsTable from '../FusionModule/FusionModuleLogsTable';
import FusionJobStatus from './FusionJobStatus';
import { useTitan } from '../Titan/Titan';
import ManufacturingOrderLink from '../ManufacturingOrders/ManufacturingOrderLink';
import FusionJobStates from './FusionJobStates';
import ManufacturingOrderActionsButton from '../ManufacturingOrders/ManufacturingOrderActionsButtons';
import useFusionModuleState from '../FusionModule/use-fusion-module-state';
import Button from '@mui/material/Button';
import FusionJobPostFusingDialog from './FusionJobPostFusingDialog';
import useAsyncEffect from 'use-async-effect';
import CopyToClipboard from '../Titan/CopyToClipboard';
import { Tooltip } from '@mui/material';
import ProjectLink from '../Projects/ProjectLink';
import TitanDuration from '../Titan/TitanDuration';

const useStyles = makeStyles((theme) => ({
  tabsRoot: {
    borderBottom: `2px solid ${colors.GREY2}`,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  tabsIndicator: {
    backgroundColor: theme.palette.primary.main,
  },
  tab: {
    '&:focus': {
      color: theme.palette.primary.main,
    },
  },
  tabSelected: {
    color: theme.palette.primary.main,
  },
  editComment: {
    marginLeft: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
  },
}));

export default function FusionJobPage() {
  const classes = useStyles();
  const { fusionJobId, tab = 'summary' } = useParams();
  const history = useHistory();
  const location = useLocation();

  const [fusionJob, setFusionJob] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const [actions, setActions] = React.useState([]);
  const [currentAction, setCurrentAction] = React.useState();

  const [openPostFusingDialog, setOpenPostFusingDialog] = React.useState(false);

  const {
    organization: { labelPrinterEnabled = false },
  } = useTitan();

  const {
    addPageToPageHistory,
    backToPreviousPage,
    getWebSocketUrl,
    getWebSocketOptions,
  } = useTitan();

  const [openEditCommentDialog, setOpenEditCommentDialog] =
    React.useState(false);

  const { lastJsonMessage } = useWebSocket(
    getWebSocketUrl,
    getWebSocketOptions(),
  );

  const breadcrumbs = React.useMemo(
    () => [
      {
        name: 'Fusion Plans',
        path: ROUTES.FUSION_PLANS('page', 0),
      },
      {
        name: fusionJob.fusionPlan ? fusionJob.fusionPlan.name : 'Fusion Plan',
        path: ROUTES.FUSION_PLAN_V2(fusionJob.fusionPlanId),
      },
      {
        name: fusionJob ? 'Fusion Job' : '',
        disabled: true,
      },
    ],
    [fusionJob],
  );

  const preparePageObject = (tab, tabForURL) => {
    return {
      id: `FUSION_JOB:${fusionJobId}`,
      url:
        tabForURL === 'Final Parts' || tabForURL === 'Logs'
          ? ROUTES.FUSION_JOB_TAB_PAGE(fusionJobId, tabForURL, 0)
          : ROUTES.FUSION_JOB_TAB(fusionJobId, tabForURL),
      label: `${fusionJob?.jobKey || `Fusion Job`} | ${tab}`,
      addInfo: {
        fusionPlan: fusionJob?.fusionPlan,
      },
    };
  };

  const pushHistory = React.useCallback(
    (tabName, settedPage) => {
      history.push(
        ROUTES.FUSION_JOB_TAB_PAGE(fusionJobId, tabName, settedPage),
      );
    },
    [fusionJobId],
  );

  const setNewPageForTab = React.useCallback(
    (tabName, labelName, settedPage) => {
      const newRoute = ROUTES.FUSION_JOB_TAB_PAGE(
        fusionJobId,
        tabName,
        settedPage,
      );
      addPageToPageHistory({
        id: `FUSION_JOB:${fusionJobId}`,
        url: newRoute,
        label:
          settedPage === 0
            ? `${fusionJob.jobKey} | ${labelName}`
            : `${fusionJob.jobKey} | ${labelName} | Page : ${settedPage + 1}`,
      });
    },
    [fusionJob],
  );

  const loadFusionJob = React.useCallback(async () => {
    setLoading(true);

    try {
      const fusionJob = await FusionJobService.getFusionJob(fusionJobId, {
        withRelated: [
          'fusionPlan',
          'fusionPlan.project',
          'fusionPlan.finalPartTypes',
          'fusionPlan.finalPartTypes.customFields',
          'fusionPlan.finalPartTypes.customFields.image',
          'fusor',
          'assemblies',
          'assemblies.customFields',
          'mould',
          'fusionPlan.member',
          'manufacturingOrder',
        ],
      });

      setFusionJob(fusionJob);
      setError(null);

      if (
        tab === 'post-fusing' &&
        [
          COMPONENT_STATUSES.POST_FUSING,
          COMPONENT_STATUSES.POST_CANCELED,
        ].includes(fusionJob.status)
      ) {
        setOpenPostFusingDialog(true);
      }
    } catch (error) {
      setError(error.response.data.message);
    }

    setLoading(false);
  }, [fusionJobId]);

  useAsyncEffect(async () => {
    await loadFusionJob();
  }, [loadFusionJob]);

  React.useEffect(() => {
    if (
      lastJsonMessage !== null &&
      lastJsonMessage.action === WEB_SOCKET_ACTIONS.FUSION_JOB &&
      lastJsonMessage.data &&
      fusionJob.id === lastJsonMessage.data.id
    ) {
      setFusionJob({ ...fusionJob, ...lastJsonMessage.data });
    }
  }, [lastJsonMessage]);

  const updateFusionJob = React.useCallback(
    async (fieldName, value, extraDataToUpdate = {}) => {
      const updatedFusionJob = await FusionJobService.update(fusionJob, {
        [fieldName]: value,
      });

      setFusionJob({ ...fusionJob, ...updatedFusionJob, ...extraDataToUpdate });
    },
    [fusionJob],
  );

  const { state: fusionModuleStatus } = useFusionModuleState(fusionJob.fusorId);

  React.useEffect(() => {
    if (!fusionJob) {
      return;
    }

    const actions = FusionJobService.getFusionJobActions({
      fusionJob,
      fusionModuleStatus,
      onFinish: () => {
        setOpenPostFusingDialog(true);
      },
      onSetAsFused: () =>
        setCurrentAction(FUSION_JOB_ACTION_TYPES.SET_AS_FUSED),
      onResend: () => setCurrentAction(FUSION_JOB_ACTION_TYPES.RESEND),
      onCancel: () => setCurrentAction(FUSION_JOB_ACTION_TYPES.CANCEL),
      onForceCancel: () =>
        setCurrentAction(FUSION_JOB_ACTION_TYPES.FORCE_CANCEL),
      onUnload: () => setCurrentAction(FUSION_JOB_ACTION_TYPES.UNLOAD),
      onForceUnload: () =>
        setCurrentAction(FUSION_JOB_ACTION_TYPES.FORCE_UNLOAD),
      onSetFusionJobOperator: () =>
        setCurrentAction(FUSION_JOB_ACTION_TYPES.SET_FUSION_JOB_OPERATOR),
      onPrintLabel: () => setCurrentAction(FUSION_JOB_ACTION_TYPES.PRINT_LABEL),
      labelPrinterEnabled,
    });

    setActions(actions);
  }, [fusionJob, fusionModuleStatus, labelPrinterEnabled]);

  return (
    <TitanPage
      loading={loading}
      error={error}
      title={fusionJob?.jobKey || 'Fusion Job'}
      breadcrumbs={breadcrumbs}
      onBackButtonClick={() => {
        backToPreviousPage(location);
      }}
      headerContent={
        <Stack spacing={2} direction="row">
          {actions.map((action, index) => {
            return action.tooltipText ? (
              <Tooltip
                title={action.tooltipText}
                showInMenu
                placement="left-start"
              >
                <span>
                  <Button
                    key={index}
                    onClick={() => action.onClick(fusionJob)}
                    variant={action.variant}
                    color={action.color}
                    startIcon={action.icon}
                    disabled={action.disabled ? action.disabled : false}
                  >
                    {action.label}
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <Button
                key={index}
                onClick={() => action.onClick(fusionJob)}
                variant={action.variant}
                color={action.color}
                startIcon={action.icon}
                disabled={action.disabled ? action.disabled : false}
              >
                {action.label}
              </Button>
            );
          })}
        </Stack>
      }
    >
      <Tabs
        classes={{
          root: classes.tabsRoot,
          indicator: classes.tabsIndicator,
        }}
        value={tab}
        onChange={(e, value) => {
          const activeLabel = value.charAt(0).toUpperCase() + value.slice(1);
          const editedActiveLabel =
            activeLabel === 'FinalParts'
              ? 'Final Parts'
              : activeLabel === 'changedConfiguration'
              ? 'Changed Configuration'
              : activeLabel;
          addPageToPageHistory(preparePageObject(editedActiveLabel, value));
          history.push(
            value === 'finalParts' || value === 'logs'
              ? ROUTES.FUSION_JOB_TAB_PAGE(fusionJobId, value, 0)
              : ROUTES.FUSION_JOB_TAB(fusionJobId, value),
          );
        }}
      >
        <Tab
          classes={{ root: classes.tab, selected: classes.tabSelected }}
          label="Summary"
          value="summary"
        />
        <Tab
          classes={{ root: classes.tab, selected: classes.tabSelected }}
          label="Final Parts"
          value="finalParts"
        />
        <Tab
          classes={{ root: classes.tab, selected: classes.tabSelected }}
          label="Telemetry"
          value="telemetry"
        />
        <Tab
          classes={{ root: classes.tab, selected: classes.tabSelected }}
          label="Logs"
          value="logs"
        />
        <Tab
          classes={{ root: classes.tab, selected: classes.tabSelected }}
          label="States History"
          value="statesHistory"
        />
        {fusionJob && !fusionJob.isAutomatic && fusionJob.finalConfiguration ? (
          <Tab
            classes={{ root: classes.tab, selected: classes.tabSelected }}
            label="Changed Configuration"
            value="changedConfiguration"
          />
        ) : (
          ''
        )}
      </Tabs>

      <TitanTabPanel index={tab} value={'summary'}>
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TitanInfoItem label="Fusion Module">
                {fusionJob.fusor ? (
                  <FusionModuleLink fusionModule={fusionJob.fusor} />
                ) : (
                  ''
                )}
              </TitanInfoItem>
            </Grid>
            <Grid item xs={4}>
              <TitanInfoItem label="Fusion Plan">
                {fusionJob.fusionPlan ? (
                  <FusionPlanLink fusionPlan={fusionJob.fusionPlan} />
                ) : (
                  ''
                )}
              </TitanInfoItem>
            </Grid>
            <Grid item xs={4}>
              <TitanInfoItem label="Status">
                {fusionJob && (
                  <FusionJobStatus
                    fusorId={fusionJob.fusorId}
                    fusionJobId={fusionJob.id}
                    initialStatus={fusionJob.status}
                    initialProgress={fusionJob.progress}
                  />
                )}
              </TitanInfoItem>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TitanInfoItem label="Fuse Start Time">
                <TitanDateTime time={fusionJob.startFuseTime} emptyValue="-" />
              </TitanInfoItem>
            </Grid>
            <Grid item xs={4}>
              <TitanInfoItem label="Fuse End Time">
                <TitanDateTime time={fusionJob.endFuseTime} emptyValue="-" />
              </TitanInfoItem>
            </Grid>
            <Grid item xs={3}>
              <TitanInfoItem label="Fuse Time">
                <TitanDuration duration={fusionJob.fuseTime} />
              </TitanInfoItem>
            </Grid>
            <Grid item xs={4}>
              <TitanInfoItem label="FMA version">
                {fusionJob.fusionModuleVersion || '-'}
              </TitanInfoItem>
            </Grid>
            <Grid item xs={4}>
              <TitanInfoItem label="Project">
                {fusionJob?.fusionPlan?.project ? (
                  <ProjectLink project={fusionJob.fusionPlan.project} />
                ) : (
                  '-'
                )}
              </TitanInfoItem>
            </Grid>
            <Grid item xs={4}>
              <TitanInfoItem label="Manufacturing Order">
                <Stack direction="row" spacing={1} alignItems="center">
                  {fusionJob.manufacturingOrderId ? (
                    <ManufacturingOrderLink
                      manufacturingOrder={fusionJob.manufacturingOrder}
                    />
                  ) : (
                    <span>-</span>
                  )}

                  {fusionJob?.fusionPlan?.projectId ? (
                    <ManufacturingOrderActionsButton
                      manufacturingOrder={fusionJob.manufacturingOrder}
                      filters={{
                        projectId: fusionJob.fusionPlan.projectId,
                        fusionPlanIds: [fusionJob.fusionPlanId],
                      }}
                      onChange={(manufacturingOrder) =>
                        updateFusionJob(
                          'manufacturingOrderId',
                          manufacturingOrder ? manufacturingOrder.id : null,
                          {
                            manufacturingOrder,
                          },
                        )
                      }
                    />
                  ) : (
                    ''
                  )}
                </Stack>
              </TitanInfoItem>
            </Grid>
            <Grid item xs={4}>
              <TitanInfoItem label="Operator">
                {fusionJob.operator &&
                fusionJob.operator.name &&
                fusionJob.operator.email ? (
                  <CopyToClipboard
                    text={fusionJob.operator.name}
                    copyValue={fusionJob.operator.email}
                    label="operators's email"
                  />
                ) : (
                  '-'
                )}
              </TitanInfoItem>
            </Grid>
            {fusionJob.status === COMPONENT_STATUSES.SCHEDULED && (
              <Grid item xs={4}>
                <TitanInfoItem label="Scheduled">
                  <TitanTimeAgo time={fusionJob.createdAt} />
                </TitanInfoItem>
              </Grid>
            )}
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TitanInfoItem label="External heating">
                {fusionJob.isExternalHeating ? 'Yes' : 'No'}
              </TitanInfoItem>
            </Grid>
            <Grid item xs={4}>
              <TitanInfoItem label="Material setup">
                {fusionJob.isMaterialSetup ? 'Yes' : 'No'}
              </TitanInfoItem>
            </Grid>
            <Grid item xs={4}>
              <TitanInfoItem label="Mould setup">
                {fusionJob.isMouldSetup ? 'Yes' : 'No'}
              </TitanInfoItem>
            </Grid>
            <Grid item xs={4}>
              <TitanInfoItem label="Mould identifier">
                {fusionJob.mould ? fusionJob.mould.mouldIdentifier : '-'}
              </TitanInfoItem>
            </Grid>
            <Grid item xs={4}>
              <TitanInfoItem label="Mode">
                {fusionJob.isAutomatic ? 'Automatic' : 'Semi-Automatic'}
              </TitanInfoItem>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TitanInfoItem label="Comment">
                {fusionJob.comment ? fusionJob.comment : '-'}
                <IconButton
                  className={classes.editComment}
                  onClick={() => setOpenEditCommentDialog(true)}
                  size="large"
                >
                  <CreateIcon />
                </IconButton>
              </TitanInfoItem>
            </Grid>
          </Grid>
        </Paper>
      </TitanTabPanel>

      <TitanTabPanel index={tab} value={'finalParts'}>
        <FinalPartsTable
          fusionJobId={fusionJob.id}
          setNewPageForTab={setNewPageForTab}
          pushHistory={pushHistory}
        />
      </TitanTabPanel>

      <TitanTabPanel index={tab} value={'telemetry'}>
        <FusionModuleTelemetry
          fusionModuleId={fusionJob.fusorId}
          fusionJobId={fusionJob.id}
          from={fusionJob.startFuseTime}
          to={fusionJob.endFuseTime}
          timeDirection="forward"
        />
      </TitanTabPanel>

      <TitanTabPanel index={tab} value={'logs'}>
        <FusionModuleLogsTable
          fusionJobId={fusionJob.id}
          setNewPageForTab={setNewPageForTab}
          pushHistory={pushHistory}
        />
      </TitanTabPanel>

      <TitanTabPanel index={tab} value={'statesHistory'}>
        <FusionJobStates fusionJob={fusionJob} />
      </TitanTabPanel>

      {fusionJob && !fusionJob.isAutomatic && fusionJob.finalConfiguration ? (
        <TitanTabPanel index={tab} value={'changedConfiguration'}>
          <FusionPlanParamsTable
            configuration={fusionJob.fusionPlan.configuration}
            finalConfiguration={fusionJob.finalConfiguration}
          />
        </TitanTabPanel>
      ) : (
        ''
      )}

      {openEditCommentDialog && (
        <CommentDialog
          comment={fusionJob.comment}
          onClose={() => setOpenEditCommentDialog(false)}
          onSave={async (comment) => updateFusionJob('comment', comment)}
        />
      )}

      <FusionJobActions
        fusionJob={fusionJob}
        currentAction={currentAction}
        onSuccess={(updatedFusionJob) => setFusionJob(updatedFusionJob)}
        onCancel={() => setCurrentAction(null)}
      />

      {openPostFusingDialog && (
        <FusionJobPostFusingDialog
          fusionJob={fusionJob}
          onClose={async () => {
            if (tab === 'post-fusing') {
              history.push({
                pathname: ROUTES.FUSION_JOB_TAB(fusionJob.id, ''),
                state: { from: location.pathname },
              });
            }

            setOpenPostFusingDialog(false);
            await loadFusionJob();
          }}
        />
      )}
    </TitanPage>
  );
}
