import React from 'react';
import { Divider, Grid } from '@mui/material';
import TitanInfoItem from '../Titan/TitanInfoItem';
import TitanDateTime from '../Titan/TitanDateTime';
import TitanTimeAgo from '../Titan/TitanTimeAgo';
import BuildModuleLink from '../BuildModule/BuildModuleLink';
import BuildPlanLink from '../BuildPlan/BuildPlanLink';
import FinalPartLink from '../FinalPart/FinalPartLink';
import Rating from '@mui/material/Rating';
import BuildJobLink from '../BuildJob/BuildJobLink';
import ComponentService from '../../services/ComponentService';
import TitanDuration from '../Titan/TitanDuration';
import TitanEditableText from '../Titan/TitanEditableText';
import Paper from '@mui/material/Paper';
import PreformService from '../../services/PreformService';
import EditableCustomField from '../CustomFields/EditableCustomField';
import { COMPONENT_STATUSES } from '../../constants';
import ComponentStatus from './ComponentStatus';
import ManufacturingOrderLink from '../ManufacturingOrders/ManufacturingOrderLink';
import CustomFieldService from '../../services/CustomFieldService';

export default function ComponentSummary({ component, setComponent }) {
  const updateComponent = async (data) => {
    const updatedComponent = await ComponentService.updateComponent(
      component.id,
      data,
    );

    setComponent({ ...component, ...updatedComponent });
  };

  const preformTypeCustomFields = React.useMemo(() => {
    const preformTypeCustomFields = component.preformType.customFields
      ? [...component.preformType.customFields]
      : [];

    preformTypeCustomFields.sort((a, b) => a.order - b.order);

    return preformTypeCustomFields;
  }, [component]);

  const preformCustomFields = React.useMemo(
    () =>
      component.customFields && component.preformType.customFields
        ? PreformService.preparePreformCustomFields(component)
        : [],
    [component],
  );

  const enableEditingCustomFields = React.useMemo(
    () =>
      [
        COMPONENT_STATUSES.POST_BUILDING,
        COMPONENT_STATUSES.BUILT,
        COMPONENT_STATUSES.BUILD_CANCELED,
        COMPONENT_STATUSES.POST_CANCELED,
        COMPONENT_STATUSES.POST_FAILED,
      ].includes(component.status),
    [component],
  );

  const componentStatus = React.useMemo(() => {
    return {
      status: component.lastState
        ? component.lastState.state
        : component.status,
      progress: component.lastState
        ? component.lastState.progress
        : component.progress,
      showStatusWarning: component.showStatusWarning,
    };
  }, [component]);

  return (
    <Paper sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TitanInfoItem label="Preform ID">
            {component.preformKey ? component.preformKey : component.id}
          </TitanInfoItem>
        </Grid>
        <Grid item xs={3}>
          <TitanInfoItem label="Device">
            {component.printer ? (
              <BuildModuleLink buildModule={component.printer} />
            ) : (
              '-'
            )}
          </TitanInfoItem>
        </Grid>
        <Grid item xs={3}>
          <TitanInfoItem label="Status">
            {componentStatus && <ComponentStatus component={componentStatus} />}
          </TitanInfoItem>
        </Grid>
        <Grid item xs={3}>
          <TitanInfoItem label="Scheduled">
            {component.status === COMPONENT_STATUSES.SCHEDULED && (
              <TitanTimeAgo time={component.createdAt} />
            )}
          </TitanInfoItem>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TitanInfoItem label="Start">
            <TitanDateTime time={component.startBuildTime} emptyValue="-" />
          </TitanInfoItem>
        </Grid>
        <Grid item xs={3}>
          <TitanInfoItem label="Completed">
            <TitanDateTime time={component.endBuildTime} emptyValue="-" />
          </TitanInfoItem>
        </Grid>

        <Grid item xs={3}>
          <TitanInfoItem label="Build Time">
            <TitanDuration duration={component.buildTime} />
          </TitanInfoItem>
        </Grid>

        <Grid item xs={3}>
          <TitanInfoItem label="Quality">
            <Rating
              name="preformQuality"
              readOnly={component.quality !== null}
              value={component.quality}
              onChange={async (event, quality) => updateComponent({ quality })}
            />
          </TitanInfoItem>
        </Grid>
        <Grid item xs={6}>
          <TitanInfoItem label="Comment">
            <TitanEditableText
              text={component.comment}
              label="Comment"
              onChangeValue={(comment) => updateComponent({ comment })}
            />
          </TitanInfoItem>
        </Grid>
        <Grid item xs={6}>
          <TitanInfoItem label="Build Job Comment">
            {component.buildJob.comment ? component.buildJob.comment : '-'}
          </TitanInfoItem>
        </Grid>
        {component.assembly ? (
          <Grid item xs={4}>
            <TitanInfoItem label="Part ID">
              <FinalPartLink
                finalPart={component.assembly}
                key={component.assemblyId}
              />
            </TitanInfoItem>
          </Grid>
        ) : (
          ''
        )}
        <Grid item xs={3}>
          <TitanInfoItem label="Build Job Print Head">
            {component.buildJob?.printHead?.printHeadId
              ? component.buildJob.printHead.printHeadId
              : '-'}
          </TitanInfoItem>
        </Grid>
        <Grid item xs={3}>
          <TitanInfoItem label="Manufacturing Order">
            {component.manufacturingOrderId ? (
              <ManufacturingOrderLink
                manufacturingOrder={component.manufacturingOrder}
              />
            ) : (
              '-'
            )}
          </TitanInfoItem>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />
      {preformTypeCustomFields.length !== 0 ? (
        <>
          <Grid container spacing={2}>
            {preformTypeCustomFields.map((preformTypeCustomField) => {
              const value = preformCustomFields[preformTypeCustomField.id]
                ? preformCustomFields[preformTypeCustomField.id].value
                : '';
              const showWarning = CustomFieldService.showCustomFieldWarning(
                preformTypeCustomField,
                value,
              );

              return (
                <Grid item xs={3} key={preformTypeCustomField.id}>
                  <TitanInfoItem
                    label={preformTypeCustomField.name}
                    showWarning={showWarning}
                  >
                    <EditableCustomField
                      enableEditing={enableEditingCustomFields}
                      customField={preformTypeCustomField}
                      value={value}
                      onChange={(value) =>
                        updateComponent({
                          customFields: Object.values({
                            ...preformCustomFields,
                            [preformTypeCustomField.id]: {
                              ...preformCustomFields[preformTypeCustomField.id],
                              value: String(value),
                            },
                          }).filter(
                            (customField) =>
                              !(
                                !customField.id &&
                                customField.value.length === 0
                              ),
                          ),
                        })
                      }
                    />
                  </TitanInfoItem>
                </Grid>
              );
            })}
          </Grid>
          <Divider sx={{ mt: 2, mb: 2 }} />
        </>
      ) : (
        ''
      )}
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TitanInfoItem label="Build Plan">
            <BuildPlanLink buildPlan={component.buildPlan} width={230} />
          </TitanInfoItem>
        </Grid>
        <Grid item xs={4}>
          <TitanInfoItem label="Build Job">
            <BuildJobLink buildJob={component.buildJob} />
          </TitanInfoItem>
        </Grid>
      </Grid>
    </Paper>
  );
}
