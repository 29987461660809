import React from 'react';
import useAsyncEffect from 'use-async-effect';
import { useBuildPlan } from './BuildPlanPage';
import { Grid, IconButton, Typography, Tooltip } from '@mui/material';
import TitanInfoItem from '../Titan/TitanInfoItem';
import TitanDateTime from '../Titan/TitanDateTime';
import TitanEditableText, {
  TITAN_INPUT_TYPES,
} from '../Titan/TitanEditableText';
import CopyToClipboard from '../Titan/CopyToClipboard';
import REGEXPS from '../../constants/regexps';
import { useAuth0 } from '../Authentication/Auth0';
import { BUILD_PLAN_SHARE_TYPES, ROLES, MATERIAL_TYPES } from '../../constants';
import BuildPlanService from '../../services/BuildPlanService';
import CreateIcon from '@mui/icons-material/Create';
import Stack from '@mui/material/Stack';
import SelectProjectDialog from '../Projects/SelectProjectDialog';
import MemberSelectionDialog from '../Fibrify/FibrifyMemberSelectionDialog';
import ShareIcon from '../../assets/icons/share-secondary.svg';
import FibrifyMaterialTypeSelectionDialog from '../Fibrify/FibrifyMaterialTypeSelectionDialog';
import TitanDuration from '../Titan/TitanDuration';
import TitanConfirmationDialog from '../Dialog/TitanConfirmationDialog';

export default function BuildPlanSummary() {
  const { buildPlan, hasPreforms, updateBuildPlan, setOpenShareDialog } =
    useBuildPlan();
  const { currentMemberId, roles, isOrganizationAdmin } = useAuth0();

  const [sharedMembers, setSharedMembers] = React.useState([]);

  const [assignee, setAssignee] = React.useState(buildPlan.assignee);

  const [openProjectsDialog, setOpenProjectsDialog] = React.useState(false);
  const [openChangeOwnerDialog, setOpenChangeOwnerDialog] =
    React.useState(false);
  const [openAssignToDialog, setOpenAssignToDialog] = React.useState(false);
  const [openPolymerDialog, setOpenPolymerDialog] = React.useState(false);
  const [openFiberDialog, setOpenFiberDialog] = React.useState(false);

  const buildPlanOwner = buildPlan.member;
  const isUserBuildPlanOwner = buildPlanOwner?.id === currentMemberId;
  const enableAssigning = isOrganizationAdmin || isUserBuildPlanOwner;

  const isBuildPlanPrivate =
    buildPlan.shareType === BUILD_PLAN_SHARE_TYPES.PRIVATE;

  const validatePlanKey = (planKey) => REGEXPS.PLAN_KEY.test(planKey);

  const validateComment = (comment) => comment.length;

  const validateJobIndex = (jobIndex) =>
    REGEXPS.LAST_JOB_INDEX.test(jobIndex) && Number(jobIndex) > 0;

  const enableEditing = React.useMemo(
    () => !hasPreforms && isUserBuildPlanOwner,
    [hasPreforms, isUserBuildPlanOwner],
  );

  const enableSharing = React.useMemo(
    () =>
      !buildPlan.deletedAt &&
      isUserBuildPlanOwner &&
      !!roles.find((r) =>
        [ROLES.ADMIN, ROLES.APPLICATION_ENGINEER].includes(r.id),
      ) &&
      !buildPlan.approvedVersion,
    [buildPlan, isUserBuildPlanOwner, roles],
  );

  const onAssignToBuildPlan = React.useCallback(
    async (member) => {
      const assigneeId = member ? member.id : null;

      await updateBuildPlan({
        assigneeId,
      });
    },
    [buildPlan, updateBuildPlan],
  );

  const onChangeBuildPlanOwner = React.useCallback(
    async (member) => {
      const memberId = member ? member.id : null;

      await updateBuildPlan({
        memberId,
      });
    },
    [buildPlan, updateBuildPlan],
  );

  React.useEffect(() => {
    if (buildPlan) {
      setAssignee(buildPlan.assignee);
    }
  }, [buildPlan]);

  useAsyncEffect(async () => {
    if (isUserBuildPlanOwner) {
      const sharedMembers = await BuildPlanService.getBuildPlanSharedMembers(
        buildPlan.id,
      );
      setSharedMembers(sharedMembers);
    }
  }, [buildPlan]);

  const disableReason = React.useMemo(() => {
    switch (true) {
      case Boolean(buildPlan.deletedAt):
        return 'Build Plan is deleted';
      case !isUserBuildPlanOwner:
        return 'Only owner can edit this field';
      case hasPreforms:
        return 'Build Plan has preforms';
      default:
        return '';
    }
  }, [buildPlan, isUserBuildPlanOwner, hasPreforms]);

  const assigneeArray = React.useMemo(
    () => (assignee ? [assignee] : []),
    [assignee],
  );

  const sharedMembersIds = React.useMemo(
    () => sharedMembers?.length > 0 && sharedMembers.map((m) => m.id),
    [sharedMembers],
  );

  const allowModifyPlan = isUserBuildPlanOwner || isOrganizationAdmin;

  const handleChangePolymerMaterial = React.useCallback(
    async (materialType) => {
      const data = {
        plasticMaterialTypeId: materialType.id,
        averagePolymerLinearDensity: materialType.averageLinearDensity ?? null,
      };

      if (
        buildPlan.estimatedPolymerLength &&
        data.averagePolymerLinearDensity
      ) {
        data.estimatedPolymer =
          parseFloat(buildPlan.estimatedPolymerLength) *
          parseFloat(data.averagePolymerLinearDensity);
      }

      await updateBuildPlan(data);
    },
    [updateBuildPlan, buildPlan],
  );

  const handleChangeFiberMaterial = React.useCallback(
    async (materialType) => {
      const data = {
        fiberMaterialTypeId: materialType.id,
        averageFiberLinearDensity: materialType.averageLinearDensity ?? null,
      };

      if (buildPlan.estimatedFiberLength && data.averageFiberLinearDensity) {
        data.estimatedFiber =
          parseFloat(buildPlan.estimatedFiberLength) *
          parseFloat(data.averageFiberLinearDensity);
      }

      await updateBuildPlan(data);
    },
    [updateBuildPlan, buildPlan],
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TitanInfoItem label="Plan Key">
            <TitanEditableText
              text={buildPlan.planKey}
              label="Plan Key"
              validator={(planKey) => validatePlanKey(planKey)}
              enableEditing={enableEditing}
              onChangeValue={async (planKey) => {
                const planKeyExists = await BuildPlanService.planKeyExists(
                  buildPlan.id,
                  planKey,
                );

                if (planKeyExists) {
                  throw new Error(
                    'Build Plan with the same Plan Key already exists within the organization',
                  );
                } else {
                  updateBuildPlan({ planKey });
                }
              }}
              disableReason={disableReason}
            />
          </TitanInfoItem>
        </Grid>

        <Grid item xs={4}>
          <TitanInfoItem label="Owner">
            {buildPlanOwner && buildPlanOwner.name && buildPlanOwner.email ? (
              <CopyToClipboard
                text={buildPlanOwner.name}
                copyValue={buildPlanOwner.email}
                label="owner's email"
              />
            ) : (
              <Stack direction="row" alignItems="center" spacing={2}>
                <span>-</span>
                <IconButton
                  size="large"
                  onClick={() => setOpenChangeOwnerDialog(true)}
                >
                  <CreateIcon />
                </IconButton>
              </Stack>
            )}
          </TitanInfoItem>
        </Grid>

        <Grid item xs={4}>
          <TitanInfoItem label="Assignee">
            <Stack direction="row" alignItems="center" spacing={2}>
              {assignee?.name && assignee?.email ? (
                <CopyToClipboard
                  text={assignee.name}
                  copyValue={assignee.email}
                  label="Assignee's email"
                />
              ) : (
                <span>-</span>
              )}

              <Tooltip
                title={
                  isBuildPlanPrivate
                    ? "You can't assign members to a private Build Plan"
                    : !enableAssigning
                    ? "Only Build Plan's owner or organization admin can edit this field"
                    : false
                }
                disableHoverListener={enableAssigning && !isBuildPlanPrivate}
                disableTouchListener={enableAssigning && !isBuildPlanPrivate}
              >
                <span>
                  <IconButton
                    size="large"
                    disabled={!enableAssigning || isBuildPlanPrivate}
                    onClick={() => setOpenAssignToDialog(true)}
                  >
                    <CreateIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
          </TitanInfoItem>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TitanInfoItem label="Project">
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography display="inline" variant="body1">
                {buildPlan.project ? buildPlan.project.name : '-'}
              </Typography>

              <Tooltip
                title={
                  !allowModifyPlan
                    ? "Only Build Plan's owner or organization admin can edit this field"
                    : false
                }
                disableHoverListener={allowModifyPlan}
                disableTouchListener={allowModifyPlan}
              >
                <div>
                  <IconButton
                    size="large"
                    onClick={() => setOpenProjectsDialog(true)}
                    disabled={!allowModifyPlan}
                  >
                    <CreateIcon />
                  </IconButton>
                </div>
              </Tooltip>
            </Stack>
          </TitanInfoItem>
        </Grid>

        <Grid item xs={4}>
          <TitanInfoItem label="Share Type">
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>{buildPlan.shareType}</Typography>
              {enableSharing && (
                <IconButton
                  onClick={() => setOpenShareDialog(true)}
                  title="Share Build Plan"
                >
                  <img src={ShareIcon} alt="" />
                </IconButton>
              )}
            </Stack>
          </TitanInfoItem>
        </Grid>

        <Grid item xs={4}>
          <TitanInfoItem label="Uploaded">
            <TitanDateTime time={buildPlan.createdAt} />
          </TitanInfoItem>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TitanInfoItem label="Spaceclaim Version">
            {buildPlan.spaceclaimVersion}
          </TitanInfoItem>
        </Grid>

        <Grid item xs={4}>
          <TitanInfoItem label="fDS Version">
            {buildPlan.fdsVersion}
          </TitanInfoItem>
        </Grid>

        <Grid item xs={4}>
          <TitanInfoItem label="Plan version">
            {buildPlan.version}
          </TitanInfoItem>
        </Grid>

        <Grid item xs={4}>
          <TitanInfoItem label="Last job index">
            <TitanEditableText
              text={buildPlan.lastJobIndex}
              label="Last job index"
              validator={(lastJobIndex) => validateJobIndex(lastJobIndex)}
              enableEditing={enableEditing}
              onChangeValue={(lastJobIndex) =>
                updateBuildPlan({ lastJobIndex })
              }
              disableReason={disableReason}
            />
          </TitanInfoItem>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TitanInfoItem label="Tape Material">
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography display="inline" variant="body1">
                {buildPlan.fiberMaterialType
                  ? buildPlan.fiberMaterialType.name
                  : '-'}
              </Typography>

              <Tooltip
                title={
                  !allowModifyPlan
                    ? "Only Build Plan's owner can edit this field"
                    : false
                }
                disableHoverListener={allowModifyPlan}
                disableTouchListener={allowModifyPlan}
              >
                <div>
                  <IconButton
                    size="large"
                    onClick={() => setOpenFiberDialog(true)}
                    disabled={!allowModifyPlan}
                  >
                    <CreateIcon />
                  </IconButton>
                </div>
              </Tooltip>
            </Stack>
          </TitanInfoItem>
        </Grid>

        <Grid item xs={4}>
          <TitanInfoItem label="Polymer Material">
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography display="inline" variant="body1">
                {buildPlan.plasticMaterialType
                  ? buildPlan.plasticMaterialType.name
                  : '-'}
              </Typography>

              <Tooltip
                title={
                  !allowModifyPlan
                    ? "Only Build Plan's owner or organization admin can edit this field"
                    : false
                }
                disableHoverListener={allowModifyPlan}
                disableTouchListener={allowModifyPlan}
              >
                <div>
                  <IconButton
                    size="large"
                    onClick={() => setOpenPolymerDialog(true)}
                    disabled={!allowModifyPlan}
                  >
                    <CreateIcon />
                  </IconButton>
                </div>
              </Tooltip>
            </Stack>
          </TitanInfoItem>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TitanInfoItem label="Tape Estimated Length">
            <TitanEditableText
              inputType={TITAN_INPUT_TYPES.NUMBER}
              text={buildPlan.estimatedFiberLength}
              label="Tape Estimated Amount"
              validator={(value) => value.length > 0}
              onChangeValue={(value) =>
                updateBuildPlan({ estimatedFiberLength: value })
              }
              enableEditing={isUserBuildPlanOwner}
              disableReason={disableReason}
              suffix="m"
            />
          </TitanInfoItem>
        </Grid>

        <Grid item xs={4}>
          <TitanInfoItem label="Polymer Estimated Length">
            <TitanEditableText
              inputType={TITAN_INPUT_TYPES.NUMBER}
              text={buildPlan.estimatedPolymerLength}
              label="Polymer Estimated Amount"
              validator={(value) => value.length > 0}
              onChangeValue={(value) =>
                updateBuildPlan({ estimatedPolymerLength: value })
              }
              enableEditing={isUserBuildPlanOwner}
              disableReason={disableReason}
              suffix="m"
            />
          </TitanInfoItem>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TitanInfoItem label="Tape Estimated Weight">
            <TitanEditableText
              inputType={TITAN_INPUT_TYPES.NUMBER}
              text={buildPlan.estimatedFiber}
              label="Tape Estimated Amount"
              validator={(value) => value.length > 0}
              onChangeValue={(value) =>
                updateBuildPlan({ estimatedFiber: value })
              }
              enableEditing={isUserBuildPlanOwner}
              disableReason={disableReason}
              suffix="g"
            />
          </TitanInfoItem>
        </Grid>

        <Grid item xs={4}>
          <TitanInfoItem label="Polymer Estimated Weight">
            <TitanEditableText
              inputType={TITAN_INPUT_TYPES.NUMBER}
              text={buildPlan.estimatedPolymer}
              label="Polymer Estimated Amount"
              validator={(value) => value.length > 0}
              onChangeValue={(value) =>
                updateBuildPlan({ estimatedPolymer: value })
              }
              enableEditing={isUserBuildPlanOwner}
              disableReason={disableReason}
              suffix="g"
            />
          </TitanInfoItem>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TitanInfoItem label="Estimated Build Time">
            {buildPlan.simulationStatus ? (
              buildPlan.simulationStatus === 'Done' ? (
                <TitanDuration duration={buildPlan.estimatedBuildTime} />
              ) : (
                `${buildPlan.simulationStatus} (${buildPlan.simulationProgress}%)`
              )
            ) : (
              '-'
            )}
          </TitanInfoItem>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TitanInfoItem label="Tags">
            {buildPlan.tags.length ? buildPlan.tags.join(', ') : '-'}
          </TitanInfoItem>
        </Grid>

        <Grid item xs={4}>
          <TitanInfoItem label="Comment">
            <TitanEditableText
              text={buildPlan.comment}
              label="Comment"
              validator={(comment) => validateComment(comment)}
              onChangeValue={(comment) => updateBuildPlan({ comment })}
              enableEditing={isUserBuildPlanOwner}
              disableReason={
                isUserBuildPlanOwner
                  ? ''
                  : 'Only plan owner can edit this field'
              }
            />
          </TitanInfoItem>
        </Grid>
      </Grid>

      {openProjectsDialog && (
        <SelectProjectDialog
          project={buildPlan.project}
          onSave={(projectId) => updateBuildPlan({ projectId })}
          onClose={() => setOpenProjectsDialog(false)}
        />
      )}

      {openChangeOwnerDialog && (
        <MemberSelectionDialog
          title="Select member which you want to set as owner of this Build Plan"
          multipleSelection={false}
          onSave={onChangeBuildPlanOwner}
          onClose={() => setOpenChangeOwnerDialog(false)}
        />
      )}

      {openAssignToDialog && (
        <MemberSelectionDialog
          title="Select member which you want to assign to this Build Plan"
          multipleSelection={false}
          currentMembers={assigneeArray}
          includeMemberIds={sharedMembersIds}
          onSave={onAssignToBuildPlan}
          onClose={() => setOpenAssignToDialog(false)}
        />
      )}

      {openPolymerDialog && (
        <FibrifyMaterialTypeSelectionDialog
          title="Select Polymer Material which you want to assign to this Build Plan"
          filters={{ type: MATERIAL_TYPES.PLASTIC }}
          currentMaterialType={
            buildPlan.plasticMaterialType ? [buildPlan.plasticMaterialType] : []
          }
          onSave={handleChangePolymerMaterial}
          onClose={() => setOpenPolymerDialog(false)}
        />
      )}

      {openFiberDialog && (
        <FibrifyMaterialTypeSelectionDialog
          title="Select Tape Material which you want to assign to this Build Plan"
          filters={{ type: MATERIAL_TYPES.FIBER }}
          currentMaterialType={
            buildPlan.fiberMaterialType ? [buildPlan.fiberMaterialType] : []
          }
          onSave={handleChangeFiberMaterial}
          onClose={() => setOpenFiberDialog(false)}
        />
      )}
    </>
  );
}
